// JS file for parent of widget iframe
function va_resizeIframe(h,m){
    if(isNaN(h)) return;
    var h2=Math.ceil(h+30);
    for(var b=document.getElementsByTagName("iframe"), c=0; c< b.length;c++){
        var d=b[c].getAttribute("src");
        (d.indexOf("https://dashboard.vakantieadressen.nl/")!=-1||(d.indexOf("https://dashboard-staging.vakantieadressen.nl/")!=-1))&&(b[c].style.height=""+h2+"px");
    }
}
window.addEventListener("message",function(m){va_resizeIframe(m.data,m)})
